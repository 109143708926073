/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Mobile Menu
        var myMenu = document.querySelector(".mobile-menu");
        var oppMenu = document.querySelector(".mobile-nav .mobile-toggle");
        var direcoesMenu = document.querySelector(".mobile-menu .direcoes-clinicas");
        var direcoesMenuToggle = document.querySelector(".mobile-menu .direcoes-clinicas span");
        var marcacaoCta = document.querySelector(".mobile-menu .marcacao-consulta");

        var direcoesDesktopToggle = document.querySelector("header .direcoes-clinicas");
        var direcoesDesktopDropdown = document.querySelector(".primary-nav .direcoes");


        function toggleClassMenu() {
          myMenu.classList.add("menu--animatable");
          if(!myMenu.classList.contains("menu--visible")) {
            myMenu.classList.add("menu--visible");
          } else {
            myMenu.classList.remove('menu--visible');
          }

          if(!oppMenu.classList.contains("menu--visible")) {
            oppMenu.classList.add("menu--visible");
          } else {
            oppMenu.classList.remove('menu--visible');
          }
        }

        function toggleElement(element) {
          if(!element.classList.contains("open")) {
            element.classList.add("open");
          } else {
            element.classList.remove('open');
          }
        }

        function OnTransitionEnd() {
          myMenu.classList.remove("menu--animatable");
        }

        oppMenu.addEventListener("click", toggleClassMenu, false);
        myMenu.addEventListener("transitionend", OnTransitionEnd, false);
        myMenu.addEventListener("click", toggleClassMenu, false);

        direcoesMenuToggle.addEventListener('click', function() {
            toggleElement(direcoesMenu);
            toggleElement(marcacaoCta);
            return false;
        });

        direcoesDesktopToggle.addEventListener('click', function() {
            toggleElement(direcoesDesktopToggle);
            toggleElement(direcoesDesktopDropdown);
            return false;
        });

        $([direcoesMenuToggle, direcoesMenu]).on('click', function(e) {
          e.stopPropagation();
        });



        // $("form .escolha-servico input:radio").click(function() {

        //     var selectValue = $(this).val();

        //     var teste = $(".escolha-servico .select-style");


        //     $(".chosen-container").hide();
        //     $("#lista" + selectValue.replace("ç", "c") + 's_chosen').show();
        // });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        var homeHero = new Swiper ('.homepage-slider', {
            loop: true,
            autoplay: 5000,
            speed: 500,
            loop: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            pagination: '.swiper-pagination',
            paginationClickable: true
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'pedido_marcacao_online': {
      init: function() {
        $("#listaExames").chosen({"width": "100%"});

        function getParameterByName(name, url) {
            if (!url) {url = window.location.href;}
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) {return null;}
            if (!results[2]) {return '';}
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        var entityMap = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          '"': '&quot;',
          "'": '&#39;',
          '/': '&#x2F;',
          '`': '&#x60;',
          '=': '&#x3D;',
          '-': '&#8211;'
        };

        function decodeHtml(html) {
          var txt = document.createElement("textarea");
          txt.innerHTML = html;
          return txt.value;
        }

        var servicoEscolhido = getParameterByName('Servico');
        var IdEscolhido = getParameterByName('ID');
        var temExames = getParameterByName('temExames');
        var clinica = getParameterByName('Clinica');

        var slideExame = getParameterByName('Exames');
        if (getParameterByName('Unidades')) {
          var slideUnidades = getParameterByName('Unidades').split(',');
        }

        var slideEspecialidades = getParameterByName('Especialidades');
        var slideServicos = getParameterByName('Servicos');

        $(".escolha-servico input[value=" + servicoEscolhido + "]").attr('checked', true);
        $(".escolha-clinicas option[value='" + clinica + "']").attr('selected', 'selected');

        if ($(".escolha-servico input[value='Especialidade']").is('[checked]')) {
          $("#grupo-especialidades").css("display","block");

          if (IdEscolhido) {
            $("#listaEspecialidades").val( $("#listaEspecialidades").find('option[data-id=' + IdEscolhido + ']').val() );
          }
        }

        if ($(".escolha-servico input[value='Exame']").is('[checked]')) {
          $("#grupo-exames").css("display","block");

          if ( isNaN(IdEscolhido)) {
            $("#listaExames").val( $("#listaExames").find("option[value='" + IdEscolhido + "']").val() );
            $("#listaExames").trigger("chosen:updated");
          } else if (!isNaN(IdEscolhido) && temExames == 0) {
            $("#listaExames").val( $("#listaExames").find('option[data-id=' + IdEscolhido + ']').val() );
            $("#listaExames").trigger("chosen:updated");
          } else if (!isNaN(IdEscolhido) && temExames == 1) {

            $.getJSON( document.location.origin + "/wp-json/acf/v3/exames/" + IdEscolhido, function( data ) {

              $("#listaExames").empty();

              var items = [];
              $.each(data['acf']['exames'], function(key, val) {
                 items.push( val['exame'] );
              });

              items.sort();

              $.each(items, function(key, val) {
                 var newOption = $('<option value="'+val+'">'+val+'</option>');
                 $('#listaExames').append(newOption);
              });

              $('#listaExames').trigger("chosen:updated");
            });
          }
        }

        if ($(".escolha-servico input[value='Serviço']").is('[checked]')) {
          $("#grupo-servicos").css("display","block");

          if (IdEscolhido) {
            $("#listaServicos").val( $("#listaServicos").find('option[data-id=' + IdEscolhido + ']').val() );
          }
        }

        $( "#dataInput" ).datepicker({
          dateFormat: "dd MM yy"
        });


        if (slideExame) {
          $(".escolha-servico input[value='Exame']").attr('checked', true);
          $("#listaExames > option").each(function() {
              if ($(this).attr("data-id") !== slideExame) {
                $(this).remove();
              }
          });
          $("#listaExames").trigger("chosen:updated");
        }

        if (slideUnidades) {
          var apiUnidades = '';
          var unidadesDisponiveis = [];

          $.each(slideUnidades, function (indexInArray, unidade) {
            apiUnidades += 'include[]='+unidade+'&';
          });

          $.getJSON("/wp-json/wp/v2/clinicas?" + apiUnidades, function( data ) {
            $.each(data, function (indexInArray, unidade) {
              unidadesDisponiveis.push(decodeHtml(unidade.title.rendered));
            });

            $(".escolha-clinicas select > option").each(function (index, option) {
              if ($.inArray($(option).val(), unidadesDisponiveis) == -1) {
                $(option).remove();
              }
            });
          });


        }

        if (slideServicos) {
          $(".escolha-servico input[value='Serviço']").attr('checked', true);
          $("#listaServicos > option").each(function () {
            if ($(this).attr("data-id") !== slideServicos) {
              $(this).remove();
            }
          });
        }

        if (slideEspecialidades) {
          $(".escolha-servico input[value='Especialidade']").attr('checked', true);
          $("#listaEspecialidades > option").each(function () {
            if ($(this).attr("data-id") !== slideEspecialidades) {
              $(this).remove();
            }
          });
        }

      }
    },
    'post_type_archive_corpoclinico': {
      init: function() {
        var input = document.getElementById('corpoclinicoFilter');
        var rows = document.querySelectorAll('.corpoclinico .row');


        function filterCorpoClinico() {
          // Declare variables
          var filter, card, a, i, txtValue;

          filter = input.value.toUpperCase();
          card = document.querySelectorAll('.medico');

          // Loop through all list items, and hide those who don't match the search query
          for (i = 0; i < card.length; i++) {
            a = card[i].getElementsByTagName("h3")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              card[i].style.display = "";
              card[i].classList.remove('hide');
            } else {
              card[i].style.display = "none";
              card[i].classList.add('hide');
            }
          }

          rows.forEach(function(row) {
            if (row.querySelectorAll('.medico').length == row.querySelectorAll('.medico.hide').length) {
              row.style.display = 'none';
              console.log(row);
            } else {
              row.style.display = '';
            }
          });
        }

        input.addEventListener("keyup", filterCorpoClinico);
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
